import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress, Typography } from "@material-ui/core";
import TableInfinityScroll from "../../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../../Table/TableHeader/TableHeader";
import ButtonWithSpinner from "../../../ButtonWithSpinner/ButtonWIthSpinner";
import { getPossibleMatches } from "../../../../store/middlewares/integrationMiddleware";
import { formDataWithApiKey } from "../../../../helpers";
import EmptyMessageBox from "../../../EmptyMessageBox/EmptyMessageBox";
import CcbIcon from "../../../../assets/img/ccb.png";

const cells = [
  {
    text: "CCB ID",
  },
  {
    text: "First Name",
  },
  {
    text: "Last Name",
  },
  {
    text: "Email",
  },
];

const PossibleMatchedStepThree = (props) => {
  const {
    // Components
    selectedHoldContacts,
    selectedPossibleMatch,
    setSelectedPossibleMatch,
    onClickedCreateNewContact,

    // Redux props
    possibleMatchesStatus,
    possibleMatches,

    // Redux functions
    getPossibleMatches,
  } = props;

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("id", selectedHoldContacts.id);
    getPossibleMatches(formData);
  }, [getPossibleMatches, selectedHoldContacts]);

  return (
    <div>
      <div className="flexer-column">
        <Typography variant="caption" className="text-muted w-80 mb-2 mt-1">
          This is your current contact
        </Typography>
        <div className="user-settings-greetings w-80 hold-contact-table-container">
          <TableInfinityScroll
            className="hold-contacts-table"
            page={1}
            fetchFunction={() => {}}
          >
            <table className="table mb-0 pb-0">
              <TableHeader
                className="hold-contacts-header-container"
                cells={cells}
              />
              <tbody className="user-settings-greetings-table-body">
                <tr className="mb-0 pb-0">
                  <td></td>
                  <td>
                    {selectedHoldContacts?.participantId ||
                      selectedHoldContacts.id}
                  </td>
                  <td>{selectedHoldContacts.firstName}</td>
                  <td>{selectedHoldContacts.lastName}</td>
                  <td>{selectedHoldContacts.email}</td>
                </tr>
              </tbody>
            </table>
          </TableInfinityScroll>
        </div>
        <Typography variant="caption" className="text-muted w-80 mb-2 mt-3">
          Click on the following contact to merge or alternatively you can
          reject merging.
        </Typography>
        <div className="user-settings-greetings w-80 hold-contact-table-container">
          {possibleMatchesStatus === "loading" ? (
            <div className="w-100 flexer overflow-hidden">
              <CircularProgress size={40} />
            </div>
          ) : (
            <TableInfinityScroll
              className="match-contacts-table"
              page={1}
              fetchFunction={() => {}}
            >
              <table className="table mb-0 pb-0">
                <TableHeader
                  className="hold-contacts-header-container"
                  cells={cells}
                />
                {Boolean(possibleMatches.length) && (
                  <tbody className="user-settings-greetings-table-body">
                    {possibleMatches.map((item, idx) => (
                      <tr
                        onClick={() => setSelectedPossibleMatch(item)}
                        className={
                          selectedPossibleMatch?.id === item?.id ? "active" : ""
                        }
                        key={idx}
                      >
                        <td></td>
                        <td>{item.participantId || item.id}</td>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>
                          {item.email}{" "}
                          <a href={item.ccbUrl} target="__blank">
                            <img src={CcbIcon} alt="icon" width={20} />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {!Boolean(possibleMatches.length) && (
                <EmptyMessageBox
                  message="No Matching Contacts"
                  hasBorder={false}
                />
              )}
              {possibleMatchesStatus === "error" && (
                <div className="text-danger text-center">
                  Something went wrong, please try again later
                </div>
              )}
            </TableInfinityScroll>
          )}
          <div className="flexer w-100 create-new-contact-btn-container">
            <ButtonWithSpinner
              onClick={onClickedCreateNewContact}
              className="bg-blue w-auto h-40px mt-2 mb-2"
            >
              Create New Contact
            </ButtonWithSpinner>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    possibleMatchesStatus: store.integrations.possibleMatchesStatus,
    possibleMatches: store.integrations.possibleMatches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPossibleMatches: (formData) => dispatch(getPossibleMatches(formData)),
  };
};

PossibleMatchedStepThree.propType = {
  selectedHoldContacts: PropTypes.object,
  selectedPossibleMatch: PropTypes.object,
  setSelectedPossibleMatch: PropTypes.func.isRequired,
  onClickedCreateNewContact: PropTypes.func.isRequired,
};

PossibleMatchedStepThree.defaultProps = {
  selectedHoldContacts: {},
  selectedPossibleMatch: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PossibleMatchedStepThree);
