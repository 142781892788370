import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Chip } from "@material-ui/core";

const BillingTableRow = (props) => {
  const { data, tableIdentity, history } = props;

  return (
    <tr className={data.isPrimary ? "bg-green" : ""}>
      <td></td>
      {tableIdentity === "isInvoices" && (
        <>
          <td>{data.date}</td>
          <td>
            {" "}
            {data.stripeLink ? (
              <a
                className="link-text"
                href={data.stripeLink || "#"}
                target="__blank"
              >
                {data.transactionNum}{" "}
                {Boolean(data?.isRefunded) && (
                  <Chip
                    className="orange-chip ml-1"
                    label={"Refunded"}
                    size="small"
                  />
                )}
              </a>
            ) : (
              <span
                onClick={() =>
                  history.push(`/billing/invoices/details/${data.id}`)
                }
                className="link-text"
              >
                {data.transactionNum} !
                {Boolean(data?.isRefunded) && (
                  <Chip
                    className="orange-chip ml-1"
                    label={"Refunded"}
                    size="small"
                  />
                )}
              </span>
            )}
          </td>
          <td>{data.amount}</td>
        </>
      )}
    </tr>
  );
};

BillingTableRow.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

BillingTableRow.defaultProps = {
  isActive: false,
};

export default withRouter(BillingTableRow);
