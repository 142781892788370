import React from "react";
import PropTypes from "prop-types";
import CreateNewMergeContact from "./CreateNewMergeContact";
import CardInfo from "../../../../HelperComponents/CardInfo";
import ListGroupItem from "../../../ListGroupItem/ListGroupItem";
import "../../../../styles/components/match-contacts.scss";
import "../../../../styles/components/more/user-settings.scss";

const MatchContactsStepFour = (props) => {
  const {
    //Component props
    isCreateNewContact,
    selectedPossibleMatch,
    selectedHoldContacts,
    handleChange,
    inputFields,
    handleRadioBtnChange,
  } = props;

  if (isCreateNewContact) {
    return (
      <div className="pl-5 pr-5">
        <CreateNewMergeContact
          inputFields={inputFields}
          selectedHoldContacts={selectedHoldContacts}
          handleChange={handleChange}
        />
      </div>
    );
  }
  return (
    <div className="w-100 flexer-space-between">
      <div className="w-60 flexer-start">
        <CardInfo
          className="w-50 no-right-border-radius m-0 pb-4"
          headerClassName={"gray-bg-card-header no-right-border-radius"}
          title={`Current (CCB ID: #${
            selectedHoldContacts?.participantId || selectedHoldContacts?.id
          })`}
        >
          <div className="list-group">
            <ListGroupItem
              label="First Name"
              currentValueToShow={selectedHoldContacts?.firstName || ""}
              withEditMode={false}
              onChangeRadioButton={handleRadioBtnChange}
              radioBtnName={"firstName"}
              radioBtnChecked={inputFields["firstName"].checked === 0}
              radioBtnValue={selectedHoldContacts?.firstName || ""}
            />
            <ListGroupItem
              label="Last Name"
              currentValueToShow={selectedHoldContacts?.lastName || ""}
              withEditMode={false}
              className="mt-1"
              onChangeRadioButton={handleRadioBtnChange}
              radioBtnName={"lastName"}
              radioBtnChecked={inputFields["lastName"].checked === 0}
              radioBtnValue={selectedHoldContacts?.lastName || ""}
            />
            <ListGroupItem
              label="Email"
              currentValueToShow={selectedHoldContacts?.email || ""}
              withEditMode={false}
              className="mt-1"
              onChangeRadioButton={handleRadioBtnChange}
              radioBtnName={"email"}
              radioBtnChecked={inputFields["email"].checked === 0}
              radioBtnValue={selectedHoldContacts?.email || ""}
            />
          </div>
        </CardInfo>
        <CardInfo
          className="w-50 no-left-border-radius m-0 pb-4"
          headerClassName={"black-bg-card-header no-left-border-radius"}
          title={`Create New (CCB ID: #${
            selectedPossibleMatch?.participantId || selectedPossibleMatch?.id
          })`}
        >
          <div className="list-group">
            <ListGroupItem
              label="First Name"
              currentValueToShow={selectedPossibleMatch?.firstName || ""}
              withEditMode={false}
              onChangeRadioButton={handleRadioBtnChange}
              radioBtnName={"firstName"}
              radioBtnChecked={inputFields["firstName"].checked === 1}
              radioBtnValue={selectedPossibleMatch?.firstName || ""}
            />
            <ListGroupItem
              label="Last Name"
              currentValueToShow={selectedPossibleMatch?.lastName || ""}
              withEditMode={false}
              className="mt-1"
              onChangeRadioButton={handleRadioBtnChange}
              radioBtnName={"lastName"}
              radioBtnChecked={inputFields["lastName"].checked === 1}
              radioBtnValue={selectedPossibleMatch?.lastName || ""}
            />
            <ListGroupItem
              label="Email"
              currentValueToShow={
                selectedPossibleMatch?.email ||
                selectedHoldContacts?.email ||
                ""
              }
              withEditMode={false}
              className="mt-1"
              onChangeRadioButton={handleRadioBtnChange}
              radioBtnName={"email"}
              radioBtnChecked={inputFields["email"].checked === 1}
              radioBtnValue={
                selectedPossibleMatch?.email ||
                selectedHoldContacts?.email ||
                ""
              }
            />
          </div>
        </CardInfo>
      </div>
      <div className="w-38">
        <CardInfo
          headerClassName={"blue-bg-card-header"}
          title={`Preview of Final Contact (CCB ID: #${
            selectedPossibleMatch?.participantId || selectedPossibleMatch?.id
          })`}
          className="pb-5"
        >
          <div className="list-group">
            <ListGroupItem
              label="First Name"
              currentValueToShow={inputFields["firstName"].value}
              withEditMode={false}
            />
            <ListGroupItem
              label="Last Name"
              currentValueToShow={inputFields["lastName"].value}
              withEditMode={false}
              className="mt-1"
            />
            <ListGroupItem
              label="Email"
              currentValueToShow={inputFields["email"].value}
              withEditMode={false}
              className="mt-1"
            />
          </div>
        </CardInfo>
      </div>
    </div>
  );
};

MatchContactsStepFour.propType = {
  isCreateNewContact: PropTypes.bool,
  selectedHoldContacts: PropTypes.object,
  selectedPossibleMatch: PropTypes.object,
  inputFields: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};

MatchContactsStepFour.defaultProps = {
  isCreateNewContact: false,
  selectedHoldContacts: {},
  selectedPossibleMatch: {},
  inputFields: {},
};

export default MatchContactsStepFour;
